/**
 * Labstep
 *
 * @module components/ProtocolStep/Action/StartEnd
 * @desc Button to complete protocolStepId
 */

import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import React from 'react';
import { IProtocolStepActionStartEndProps } from './types';

const ProtocolStepActionStartEnd: React.FC<
  IProtocolStepActionStartEndProps
> = ({
  protocolStep,
  protocolSteps,
  actionComponentProps,
  endedAt,
}) => {
  let protocolStepId;
  if (protocolSteps) {
    protocolStepId = protocolSteps.map((step) => step.guid);
  } else {
    protocolStepId = protocolStep.guid;
  }
  return (
    <EntityUpdateContainer
      entityName={ProtocolStep.entityName}
      id={protocolStepId}
    >
      {({ update, status }) => (
        <ActionComponent
          dataCy="components/ProtocolStep/Action/StartEnd"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            update({
              ended_at:
                endedAt === null ? null : generateNewDateString(),
            });
          }}
          status={status}
          {...actionComponentProps}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default ProtocolStepActionStartEnd;
