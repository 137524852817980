/**
 * Labstep
 *
 * @module core/Select/Multi
 * @desc Input with tokenized emails
 * @see https://react-select.com/creatable
 */

import { difference } from 'lodash';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ISelectMultiProps, ISelectMultiState } from './types';

const components = {
  DropdownIndicator: null,
};

export const createOption = (label: string) => ({
  label,
  value: label,
});

/** @var string Default placeholder */
const defaultPlaceholder =
  'Enter values and press enter (separation by comma)';

/** @var string Email placeholder */
const emailPlaceholder = 'Enter email addresses';

/** @var RegExp Split by comma */
export const defaultRegExp = /[^,]+/gi;

// From: https://stackoverflow.com/a/5284410
/** @var RegExp Split by comma */
export const ipRegExp =
  /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/gi;

/** @var RegExp Split by email (RFC2822) */
export const emailRegExp =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

/**
 * Extract values based on given regular expression.
 * Trim found values.
 */
export const extractValues = (
  string: string,
  regExp: RegExp,
): string[] => {
  const matches = string.match(regExp);
  if (!matches) {
    return [];
  }
  return matches.map((value: string) => value.trim()).filter(Boolean);
};

export class SelectMulti extends React.Component<
  ISelectMultiProps,
  ISelectMultiState
> {
  constructor(props: ISelectMultiProps) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = {
      inputValue: '',
      value: [],
    };
  }

  componentDidMount() {
    if (this.props.defaultValues) {
      if (Array.isArray(this.props.defaultValues)) {
        this.setState({
          value: this.props.defaultValues.map((newValue: string) =>
            createOption(newValue),
          ),
        });
      } else {
        this.setState({
          value: Object.keys(this.props.defaultValues).map(
            (newValue: string) => createOption(newValue),
          ),
        });
      }
    }
  }

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        this.addValues();
        event.preventDefault();
        break;
      }
      default:
        break;
    }
  };

  /**
   * This is for handling clearing
   */
  handleOnChange(value: any) {
    const { onChange } = this.props;
    const newValue = value || [];
    this.setState({ value: newValue });
    onChange(newValue.map((option: any) => option.value));
  }

  addValues = () => {
    const { value, inputValue } = this.state;
    const { onChange, pattern } = this.props;

    if (!inputValue) {
      return;
    }

    const existingEmails = value.map((option: any) => option.value);

    let regExp = defaultRegExp;
    if (pattern === 'email') {
      regExp = emailRegExp;
    } else if (pattern === 'ip') {
      regExp = ipRegExp;
    }

    const extractedValues = extractValues(inputValue, regExp);

    const newEmails = difference(extractedValues, existingEmails);
    const options = newEmails.map((newValue: string) =>
      createOption(newValue),
    );
    const newValue = [...value, ...options];
    onChange(newValue.map((option) => option.value));
    this.setState({
      inputValue: '',
      value: newValue,
    });
  };

  render() {
    const { inputValue, value } = this.state;
    const { pattern, autoFocus } = this.props;

    const placeholder =
      pattern === 'email' ? emailPlaceholder : defaultPlaceholder;

    return (
      <CreatableSelect
        autoFocus={autoFocus}
        components={components}
        inputValue={inputValue}
        isClearable={false}
        onChange={this.handleOnChange}
        onBlur={this.addValues}
        isMulti
        menuIsOpen={false}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown as any}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}

export default SelectMulti;
