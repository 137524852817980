/**
 * Labstep
 *
 * @module components/ProtocolCollection/Action/Run
 * @desc Run a protocolCollection
 */

import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import React from 'react';
import { IProtocolCollectionActionRunProps } from './types';

const ProtocolCollectionActionRun: React.FC<
  IProtocolCollectionActionRunProps
> = ({ protocolCollection }) => (
  <ExperimentWorkflowActionCreate
    actionComponentProps={{
      dataCy: 'components/ProtocolCollection/Action/Run',
      type: 'button',
      elementProps: { size: 'small' },
    }}
    protocol={protocolCollection.last_version}
  />
);

export default ProtocolCollectionActionRun;
