/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/Amount
 * @desc ProtocolValue Amount
 */

import { protocolValueFieldAmount } from 'labstep-web/components/ProtocolValue/Form/fields';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import React from 'react';
import { IProtocolValueShowEditAmountProps } from './types';

export const ProtocolValueShowEditAmount: React.FC<
  IProtocolValueShowEditAmountProps
> = ({ protocolValue, placeholder }) => (
  <ShowEditText
    dataCy="components/ProtocolValue/Form/ShowEdit/Amount"
    disabled={protocolValue.has_non_limiting_reactant}
    id={protocolValue.guid}
    entityName={protocolValue.entityName}
    entity={protocolValue}
    field={protocolValueFieldAmount}
    placeholder={placeholder}
  >
    {protocolValue.amount}
  </ShowEditText>
);

export default ProtocolValueShowEditAmount;
