/**
 * Labstep
 */

import { OrganizationPlan } from 'labstep-web/models/organization-plan.model';
import CurrencyService, {
  Currency,
} from 'labstep-web/services/currency.service';

export function getPrice(
  organizationPlan: OrganizationPlan,
  quantity: number,
): string {
  return CurrencyService.print(
    organizationPlan.amount * quantity,
    organizationPlan.currency.toUpperCase() as Currency,
    false,
  );
}

export function getIntervalAdverb(interval: string): string {
  return interval.toLocaleLowerCase() === 'year'
    ? 'Annual'
    : 'Monthly';
}
