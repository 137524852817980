/**
 * Labstep
 *
 * @module components/Entity/Action/Edit
 * @desc Button to edit entity
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IEntityActionEditProps } from './types';

export const EntityActionEdit: React.FC<IEntityActionEditProps> = ({
  entity,
  actionComponentProps,
  renderIfNoEditPermissions,
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
  ) as boolean;

  if (!canEdit && !renderIfNoEditPermissions) {
    return null;
  }

  return (
    <ActionComponent
      type="option"
      text="Edit"
      icon="pencil"
      disabled={!canEdit}
      {...actionComponentProps}
    />
  );
};

export default EntityActionEdit;
