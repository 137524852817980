/**
 * Labstep
 *
 * @module core/Modal/Default
 * @desc Default Modal
 */

import cx from 'classnames';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import Button from 'labstep-web/core/Button';
import { ModalHOC } from 'labstep-web/hoc/Modal';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Modal from '..';
import styles from './styles.module.scss';
import './styles.scss';
import {
  IDefaultModalContainerProps,
  IDefaultModalProps,
} from './types';

export const ModalDefault: React.FC<IDefaultModalProps> = (props) => {
  const {
    id,
    dataTestId,
    actions,
    content,
    closeIcon = true,
    closeOnDimmerClick = true,
    header,
    headerColor,
    modalHocProps,
    inverted,
    onClose,
    showDone,
    formatContent = true,
    preventScroll,
    footer,
    ...rest
  } = props;

  const stylesContent: any = styles.content;
  const classes = cx(
    styles.modal,
    { [styles.inverted]: inverted },
    { [styles.fullscreen]: rest.size === 'fullscreen' },
  );
  const childrenClass = cx({ [styles.inverted]: inverted });
  const contentClass = cx({
    [styles.inverted]: inverted,
    [styles.scrolling]: rest.size === 'large' && !preventScroll,
    ...stylesContent,
  });

  // TODO: Refactor with redux
  const enhancedModalHocProps = {
    ...modalHocProps,
    toggleModal: () => {
      modalHocProps.toggleModal();
      setTimeout(() => {
        if (document.getElementById('dropdown-modal') !== null) {
          const element: Element =
            document.getElementsByClassName('dimmer')[0];
          if (element instanceof HTMLElement) {
            element.click();
          }
        }
      }, 0);
    },
  };

  const hideCloseIcon = rest.size === 'large' && !header;

  return (
    <Modal
      data-cy="core/Modal/Default"
      id={id || 'default-modal'}
      data-testid={dataTestId || 'default-modal'}
      className={classes}
      closeOnDimmerClick={closeOnDimmerClick}
      closeIcon={closeIcon && !hideCloseIcon}
      size="tiny"
      onClose={
        onClose
          ? () =>
              onClose({
                toggleModal: enhancedModalHocProps.toggleModal,
              })
          : enhancedModalHocProps.toggleModal
      }
      open={modalHocProps && modalHocProps.open}
      onClick={(evt: Event) => evt.stopPropagation()}
      onDoubleClick={(evt: Event) => evt.stopPropagation()}
      closeOnEscape={false}
      onKeyDown={(e: Event) => e.stopPropagation()}
      {...rest}
    >
      {formatContent ? (
        <>
          {header && (
            <Header className={childrenClass} color={headerColor}>
              {objectOrFunction(header, enhancedModalHocProps)}
            </Header>
          )}
          {content && (
            <Modal.Content
              className={contentClass}
              data-cy="core/Modal/Default:Content"
            >
              <>{objectOrFunction(content, enhancedModalHocProps)}</>
            </Modal.Content>
          )}
          {(footer || showDone || actions) && (
            <Modal.Actions>
              <LayoutContainerActionButtons>
                {showDone && (
                  <Button
                    onClick={
                      onClose
                        ? () =>
                            onClose({
                              toggleModal:
                                enhancedModalHocProps.toggleModal,
                            })
                        : enhancedModalHocProps.toggleModal
                    }
                    primary
                    fluid
                  >
                    Done
                  </Button>
                )}
                {objectOrFunction(actions, modalHocProps)}
              </LayoutContainerActionButtons>
              <>{objectOrFunction(footer, enhancedModalHocProps)}</>
            </Modal.Actions>
          )}
        </>
      ) : (
        objectOrFunction(content, enhancedModalHocProps)
      )}
    </Modal>
  );
};

const ModalDefaultContainer: React.FC<
  IDefaultModalContainerProps
> = ({ viewComponent, initialModalState, ...rest }) => (
  <ModalHOC
    initialModalState={initialModalState}
    viewComponent={viewComponent}
    modalComponent={(modalHocProps) => (
      <ModalDefault {...rest} modalHocProps={modalHocProps} />
    )}
  />
);

export default ModalDefaultContainer;
