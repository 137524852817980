/**
 * Labstep
 *
 * @module screens/ProtocolCollection/Show/Center
 * @desc Center Component of Protocol Show
 * Shows alert if the user cannot edit this version
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import FolderManager from 'labstep-web/components/Folder/Manager';
import ProtocolActionSave from 'labstep-web/components/Protocol/Action/Save';
import ProtocolChangelog from 'labstep-web/components/Protocol/Changelog';
import ProtocolFormShowEditState from 'labstep-web/components/Protocol/Form/ShowEdit/State/Container';
import ProtocolCollectionActionMenu from 'labstep-web/components/ProtocolCollection/Action/Menu';
import { Action } from 'labstep-web/components/ProtocolCollection/Action/Menu/types';
import LockMessage from 'labstep-web/components/ProtocolCollection/Info/LockMessage';
import TagManager from 'labstep-web/components/Tag/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import styles from './styles.module.scss';
import { ICenterProps } from './types';

const ProtocolCollectionShowCenter: React.FC<ICenterProps> = ({
  protocolCollection,
}) => {
  const protocol = protocolCollection.last_version;

  return (
    <div className={styles.container}>
      <InformationCard
        additionalActions={
          <>
            <EntityUserCard entity={protocolCollection} />
            <FolderManager parentEntity={protocolCollection} />
            <TagManager parentEntity={protocolCollection} />
          </>
        }
        actionMenu={
          <ProtocolCollectionActionMenu
            protocolCollection={protocolCollection}
            actions={[
              Action.add_to_folder,
              Action.tag,
              Action.add_to_experiment,
              Action.duplicate,
              Action.print,
              Action.delete,
              Action.share,
              Action.transfer_ownership,
              Action.allowed_actions_lock_settings,
            ]}
          />
        }
        primaryInfo={
          <EntityPrimaryInfo
            entity={protocolCollection}
            icon={ICONS.protocol_collection.primary}
          />
        }
        secondaryInfo={
          <Flex vAlignContent="center" wrap>
            <ProtocolChangelog
              protocol={protocol}
              protocolCollection={protocolCollection}
            />
            <ProtocolActionSave
              protocolCollection={protocolCollection}
            />
            <ExperimentWorkflowActionCreate
              protocol={protocol}
              actionComponentProps={{
                type: 'text',
                icon: 'check circle',
              }}
            />
          </Flex>
        }
        bottom={
          <>
            <LockMessage
              protocol={protocolCollection.last_version}
              protocolCollection={protocolCollection}
            />
            <ProtocolFormShowEditState
              protocolCollection={protocolCollection}
              protocol={protocol}
            />
          </>
        }
      />
    </div>
  );
};

export default ProtocolCollectionShowCenter;
