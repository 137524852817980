/**
 * Labstep
 */

import {
  CellClassParams,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  GridApi,
  ProcessDataFromClipboardParams,
} from 'ag-grid-community';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { validateEmail } from 'labstep-web/state/selectors/helpers';
import React from 'react';
import { User } from './types';

export const DEFAULT_ROW_COUNT = 4;
export const ADDING_ROW_CTA = 'Add row';

export const getNewUsersCount = (gridApi: GridApi | null) => {
  if (!gridApi) {
    return 0;
  }
  let newUsersCount = 0;
  gridApi.forEachNode((rowNode) => {
    const data = rowNode.data as User;
    if (
      data.firstName !== ADDING_ROW_CTA &&
      data.firstName !== '' &&
      data.lastName !== '' &&
      data.email !== ''
    ) {
      newUsersCount += 1;
    }
  });

  return newUsersCount;
};

export const isInvalidGrid = (
  params: CellValueChangedEvent,
): boolean => {
  const rowCount = params.api.getDisplayedRowCount();
  for (let i = 0; i < rowCount; i += 1) {
    const rowNode = params.api.getDisplayedRowAtIndex(i);
    const data = rowNode!.data as User;

    if (
      (data.email &&
        (!data.firstName ||
          !data.lastName ||
          !validateEmail(data.email))) ||
      (data.firstName &&
        data.firstName !== ADDING_ROW_CTA &&
        (!data.email || !data.lastName)) ||
      (data.lastName && (!data.email || !data.firstName))
    ) {
      return true;
    }
  }
  return false;
};

export const getCellStyle = (params: CellClassParams) => {
  const { email, firstName, lastName } = params.data as User;

  if (params.colDef.field === 'firstName') {
    if (!params.value && (lastName || email)) {
      return { backgroundColor: '#F5B1B1' };
    }
  } else if (params.colDef.field === 'lastName') {
    if (
      !params.value &&
      ((firstName && firstName !== ADDING_ROW_CTA) || email)
    ) {
      return { backgroundColor: '#F5B1B1' };
    }
  } else if (params.colDef.field === 'email') {
    if (!params.value) {
      if ((firstName && firstName !== ADDING_ROW_CTA) || lastName) {
        return { backgroundColor: '#F5B1B1' };
      }
    } else if (!validateEmail(params.value)) {
      return { backgroundColor: '#F5B1B1' };
    }
  }

  return null;
};

export const getEditable = (params: EditableCallbackParams) => {
  if (
    params.data.firstName === ADDING_ROW_CTA &&
    params.column.getColId() !== 'firstName'
  ) {
    return false;
  }
  return true;
};

export const cellRenderer = (params: any) => {
  const data = params.data as User;
  let children = 'Enter';
  let editable = true;

  if (data.firstName === ADDING_ROW_CTA) {
    if (params.column.colId === 'firstName') {
      children = ADDING_ROW_CTA;
    } else {
      children = ' ';
      editable = false;
    }
  }
  if (params.value === '' || params.value === ADDING_ROW_CTA) {
    return (
      <DataGridPlaceholder
        params={params}
        editable={editable}
        children={children}
        placeholder={' '}
      />
    );
  }
  return params.value;
};

export const columnDefs: ColDef[] = [
  {
    headerName: 'First Name',
    field: 'firstName',
    cellRenderer,
    editable: getEditable,
    cellStyle: getCellStyle,
    flex: 1,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    editable: getEditable,
    cellRenderer,
    cellStyle: getCellStyle,
  },
  {
    headerName: 'Email Address',
    field: 'email',
    cellRenderer,
    editable: getEditable,
    cellStyle: getCellStyle,
  },
];

export const getDefaultRowData = (availableSeats?: number) => {
  let maxRowCount = DEFAULT_ROW_COUNT;
  if (availableSeats && availableSeats < DEFAULT_ROW_COUNT) {
    maxRowCount = availableSeats;
  }
  const rows: User[] = Array.from({ length: maxRowCount }, () => ({
    firstName: '',
    lastName: '',
    email: '',
  }));
  if (!availableSeats || availableSeats > DEFAULT_ROW_COUNT) {
    rows.push({ firstName: ADDING_ROW_CTA, lastName: '', email: '' });
  }

  return { rowData: rows, rowCount: maxRowCount };
};

export const onCellEditingStarted = (
  params: CellEditingStartedEvent,
  availableSeats?: number,
) => {
  if (
    params.column.getColId() === 'firstName' &&
    params.data.firstName === ADDING_ROW_CTA
  ) {
    const user = params.data as User;
    params.node.setData({
      ...user,
      firstName: '',
    });

    // Prevents the user from adding a new row when there are no available seats
    if (
      availableSeats &&
      params.api.getDisplayedRowCount() >= availableSeats
    ) {
      return;
    }
    params.api.applyTransaction({
      add: [{ firstName: ADDING_ROW_CTA, lastName: '', email: '' }],
      addIndex: params.node.rowIndex ? params.node.rowIndex + 1 : 1,
    });
  }
};

export const onCellEditingStopped = (
  params: CellEditingStoppedEvent,
) => {
  const user = params.data as User;
  if (user.firstName === ADDING_ROW_CTA) {
    params.node.setData({
      ...user,
      firstName: '',
    });
  }
};

export const getEditableRowCount = (api: GridApi) => {
  let editableRows = api.getDisplayedRowCount();
  const lastRowNode = api.getDisplayedRowAtIndex(editableRows - 1);

  // If the last row is for adding new row, don't count it as this is not editable
  if ((lastRowNode!.data as User).firstName === ADDING_ROW_CTA) {
    editableRows -= 1;
  }

  return editableRows;
};

export const processPastedData = (
  { api, data }: ProcessDataFromClipboardParams,
  availableSeats?: number,
) => {
  if (!api) {
    return;
  }
  const focusedCell = api.getFocusedCell();
  const focusedRowIndex = focusedCell!.rowIndex;
  const focusedRow = api.getDisplayedRowAtIndex(focusedRowIndex);

  const editableRows = getEditableRowCount(api);

  const rowsToAdd = data.length - (editableRows - focusedRowIndex);

  const addIndex =
    focusedRow?.data.firstName === ADDING_ROW_CTA
      ? editableRows
      : editableRows - 1;

  const canAddRowsCount = availableSeats
    ? availableSeats - (addIndex + 1)
    : rowsToAdd;

  if (rowsToAdd > 0 && canAddRowsCount > 0) {
    const newRows = Array.from(
      {
        length:
          rowsToAdd < canAddRowsCount ? rowsToAdd : canAddRowsCount,
      },
      () => ({
        firstName: '',
        lastName: '',
        email: '',
      }),
    );
    api.applyTransaction({
      add: newRows,
      addIndex,
    });

    const displayedRowCount = api.getDisplayedRowCount();

    // If displayed row count is more than available seats, remove the last row that is for adding new row.
    if (availableSeats && displayedRowCount > availableSeats) {
      const lastRowIndex = displayedRowCount - 1;
      const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex);
      api.applyTransaction({
        remove: [lastRowNode!.data],
      });
    }

    api.redrawRows();
  }
};
