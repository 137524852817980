/**
 * Labstep
 *
 * @module components/Metadata/Action/Delete
 * @desc Delete action for metadata
 */

import EntityActionSofDelete from 'labstep-web/components/Entity/Action/Delete';
import Message from 'labstep-web/core/Message';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import { IMetadataActionDeleteProps } from './types';

export const MetadataActionDelete: React.FC<
  IMetadataActionDeleteProps
> = ({ entity, metadata }) => (
  <EntityActionSofDelete
    dataCy="components/Metadata/Action/Delete"
    confirmMessage={
      !metadata.is_template ? undefined : (
        <>
          <Message info color="red">
            If you delete this field it will no longer appear on any
            resources using this template.
          </Message>
          <p>
            Are you sure you want to delete this metadata field from
            the template?
          </p>
        </>
      )
    }
    entity={metadata}
    humanReadableName={Metadata.getHumanReadableEntityName(
      false,
      true,
      entity,
    )}
  />
);

export default MetadataActionDelete;
