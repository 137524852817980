/**
 * Labstep
 *
 * @module services/ag-grid-export
 * @desc AGGrid Service Export
 */

import { ProcessCellForExportParams } from 'ag-grid-community';

export class AGGridEntityExportService {
  public static processCellCallback(
    params: ProcessCellForExportParams,
  ) {
    const colDef = params.column.getColDef();
    if (colDef) {
      const { valueGetterExport } = colDef as any;
      if (
        valueGetterExport &&
        typeof valueGetterExport === 'function'
      ) {
        return valueGetterExport(params);
      }

      if (params.value) {
        return params.value;
      }
    }
    return '';
  }

  public static defaultCsvExportParams = {
    processCellCallback:
      AGGridEntityExportService.processCellCallback,
  };

  public static defaultExcelExportParams = {
    processCellCallback:
      AGGridEntityExportService.processCellCallback,
  };
}
