/**
 * Labstep
 */

import EntityActionUpdateTemplate from 'labstep-web/components/Entity/Action/UpdateTemplate';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import FilterTemplate from 'labstep-web/components/Filter/Template';
import { ICONS } from 'labstep-web/constants/icons';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  getEditable,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import React from 'react';
import colDefEntityLink from '../entityLink';
import { colDefTemplateType } from './types';

/**
 * Column definition for template
 */
const colDefTemplate: colDefTemplateType = (
  entityName,
  setParams,
  options,
) => {
  const colDef = {
    ...colDefEntityLink(options),
    colId: 'template',
    editable: getEditable(),
    cellEditor: (params) => (
      <EntityActionUpdateTemplate
        entity={params.data}
        options={{ onSuccess: () => stopEditingAndFocus(params) }}
        modalProps={{
          open: true,
          onClose: () => stopEditingAndFocus(params),
          viewComponent: null,
        }}
      />
    ),
    cellEditorPopup: true,
    valueGetter: (params) => params.data?.template?.idAttr || null,
    valueGetterExport: (params) =>
      params.node?.data?.template?.name || 'Untitled Category',
    valueSetter: (params) =>
      setValue('template_id', params.newValue, {
        entityName: params.data?.entityName,
        id: params.data?.idAttr,
      }),
    headerName: 'Category',
    headerComponentParams: {
      icon: ICONS.resource_template.primary,
      filter: (props) => (
        <FilterTemplate entityName={entityName} {...props} />
      ),
    },
  };
  if (setParams) {
    colDef.cellRenderer = (params) => {
      const entity = params.data?.template;
      if (!entity) {
        return (
          <DataGridPlaceholder
            params={params}
            editable={getEditable()}
            children="Specify category"
          />
        );
      }
      return (
        <EntityLinkComponent
          entity={entity}
          onClick={() => setParams?.({ template_id: entity.id })}
          disabled={!setParams}
        />
      );
    };
  }
  return colDef;
};

export default colDefTemplate;
