/**
 * Labstep
 *
 * @module components/Group/Settings/Protocols
 */

import EntityActionUpdateAllowedActionsLockContent from 'labstep-web/components/Entity/Action/Update/AllowedActionsLock/Content';
import LayoutLinksState from 'labstep-web/components/Layout/Links/State';
import { TagType } from 'labstep-web/models/tag.model';
import TagIndex from 'labstep-web/screens/Tag/Index';
import React from 'react';
import styles from './styles.module.scss';
import { IGroupSettingsProtocolsProps } from './types';

const GroupSettingsProtocols: React.FC<
  IGroupSettingsProtocolsProps
> = ({ group }) => {
  return (
    <div id="group-settings-protocols" style={{ flex: 1 }}>
      <LayoutLinksState
        inverted
        tabs={[
          {
            key: 'Tags',
            render: () => (
              <TagIndex
                group={group}
                type={TagType.protocol_collection}
              />
            ),
          },
          {
            key: 'Lock Settings',
            render: () => (
              <div className={styles.container}>
                <EntityActionUpdateAllowedActionsLockContent
                  entity={group}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default GroupSettingsProtocols;
