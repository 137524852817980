/**
 * Labstep
 *
 * @module components/Metadata/Action/Create/Dropdown
 * @desc dropdown for metadata
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import MediaQuery from 'labstep-web/core/MediaQuery';
import { Size } from 'labstep-web/core/MediaQuery/types';
import { usePremiumFeatureStatus } from 'labstep-web/hooks/premium-feature';
import {
  METADATA_TYPES,
  METADATA_TYPES_DATA,
} from 'labstep-web/models/metadata/constants';
import React from 'react';
import { BULK_OPTION } from './constants';
import { IMetadataActionCreateDropdownProps } from './types';

export const MetadataActionCreateDropdown: React.FC<
  IMetadataActionCreateDropdownProps
> = ({
  trigger,
  handleOnClickMetadataOption,
  handleOnClickBulkOption,
  showChemistry,
}) => {
  const options = showChemistry
    ? METADATA_TYPES
    : METADATA_TYPES_DATA;

  const metadataOptions = usePremiumFeatureStatus(
    options,
    'value',
    'metadata',
  );

  return (
    <Dropdown
      trigger={trigger}
      closeOnChange
      icon={null}
      data-cy="components/Metadata/Action/Create/Dropdown"
    >
      {metadataOptions.map((metadataOption) => {
        return (
          <ActionComponent
            key={metadataOption.value}
            onClick={() => {
              handleOnClickMetadataOption(metadataOption);
            }}
            dataTestId={metadataOption.label}
            type="option"
            text={metadataOption.label}
            premiumFeature={metadataOption.premiumFeature}
          />
        );
      })}
      {handleOnClickBulkOption && (
        <MediaQuery size={Size.tablet_desktop}>
          <Dropdown.Divider key={metadataOptions.length} />
          <Dropdown.Item
            key={metadataOptions.length + 1}
            onClick={handleOnClickBulkOption}
            data-testid={BULK_OPTION.label}
          >
            {BULK_OPTION.label}
          </Dropdown.Item>
        </MediaQuery>
      )}
    </Dropdown>
  );
};

export default MetadataActionCreateDropdown;
