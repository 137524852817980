/**
 * Labstep
 *
 * @module components/ProtocolTimer/Action/Menu
 * @desc Action Menu for ProtocolTimer
 */

import ProtocolTimerActionDelete from 'labstep-web/components/ProtocolTimer/Action/Delete';
import ProtocolTimerActionEdit from 'labstep-web/components/ProtocolTimer/Action/Edit';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { IProtocolTimerActionMenuProps } from './types';

const ProtocolTimerActionMenu: React.FC<
  IProtocolTimerActionMenuProps
> = ({ protocolTimer, type, actions = [] }) => {
  return (
    <ActionMenu type={type}>
      {actions.indexOf('edit') > -1 && (
        <ProtocolTimerActionEdit protocolTimer={protocolTimer} />
      )}
      {actions.indexOf('delete') > -1 && (
        <ProtocolTimerActionDelete protocolTimer={protocolTimer} />
      )}
    </ActionMenu>
  );
};

ProtocolTimerActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ProtocolTimerActionMenu;
