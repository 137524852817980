/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/Menu
 * @desc Action Menu for ProtocolValue
 */

import ProtocolValueActionDelete from 'labstep-web/components/ProtocolValue/Action/Delete';
import ProtocolValueActionView from 'labstep-web/components/ProtocolValue/Action/View';
import ActionMenu from 'labstep-web/core/Action/Menu';
import React from 'react';
import { Action, IProtocolValueActionMenuProps } from './types';

const ProtocolValueActionMenu: React.FC<
  IProtocolValueActionMenuProps
> = ({ protocolValue, type, actions = [] }) => {
  return (
    <ActionMenu
      type={type}
      dataCy="components/ProtocolValue/Action/Menu"
    >
      {actions.indexOf(Action.view) > -1 && (
        <ProtocolValueActionView protocolValue={protocolValue} />
      )}
      {actions.indexOf(Action.delete) > -1 && (
        <ProtocolValueActionDelete protocolValue={protocolValue} />
      )}
    </ActionMenu>
  );
};

ProtocolValueActionMenu.defaultProps = {
  actions: [],
  type: 'icon',
};

export default ProtocolValueActionMenu;
