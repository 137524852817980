/**
 * Labstep
 *
 * @module services/intercom
 * @desc Intercom integration
 */

import { User } from 'labstep-web/models/user.model';
import { configService } from '../config.service';
import { loadScript } from './load-script';

export class IntercomService {
  window: Window;

  intercomAppId: string;

  release: string;

  constructor(window: Window) {
    this.intercomAppId = configService.intercomAppId;
    this.release = configService.labstepEnv;
    this.window = window;

    if (configService.debug) {
      return;
    }
    loadScript(configService.intercomAppId);
  }

  isInitialized = () =>
    Boolean(this.window.Intercom && this.intercomAppId);

  open = () => {
    if (!this.isInitialized()) {
      return;
    }
    this.window.Intercom('show');
  };

  /**
   * Initialises intercom service
   */
  init = () => {
    if (!this.isInitialized()) {
      return;
    }
    this.window.Intercom('boot', {
      app_id: this.intercomAppId,
      custom_launcher_selector: '.custom-intercom-launcher',
    });
    if (this.release !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Intercom initialised');
    }
  };

  /**
   * Updates intercom with user details
   */
  identify = (user: User) => {
    if (!this.isInitialized()) {
      return;
    }

    const testing = user.testing
      ? {
          intercom_support_popup: 'default',
          default_entity_creation:
            user.testing.default_entity_creation,
          default_entity_creation_prose_mirror_experiments:
            user.testing
              .default_entity_creation_prose_mirror_experiments,
          email_re_engagement: user.testing.email_re_engagement,
        }
      : {};

    const intercomSettings: any = {
      app_id: this.intercomAppId,
      user_id:
        configService.awsRegion === 'us-west-2' ? user.guid : user.id,
      name: user.name,
      email: user.username,
      enabled_db: user.enabled_db,
      deleted_at: user.deleted_at,
      ...testing,
    };
    const userOrganization = user.user_organizations[0];
    if (userOrganization) {
      const formatDateForIntercom = (date: string): number => {
        // eslint-disable-next-line no-console
        console.log('date=', date);
        return new Date(date).getTime() / 1000;
      };
      intercomSettings.organization_role = userOrganization.type;
      const { organization } = userOrganization;
      intercomSettings.company = {
        id:
          configService.awsRegion === 'us-west-2'
            ? organization.guid
            : organization.id,
        name: organization.name,
        identifier: organization.identifier,
        plan: organization.tier,
        created_at: formatDateForIntercom(organization.created_at),
        is_trialling: organization.is_trialling,
        trial_ends_at: formatDateForIntercom(
          organization.trial_ends_at,
        ),
      };
    }

    this.window.Intercom('boot', intercomSettings);
  };

  trackEvent = (eventName: string, metadata: any) => {
    if (!this.isInitialized()) {
      return;
    }
    this.window.Intercom('trackEvent', eventName, metadata);
  };

  sendMessage = (message: string) => {
    if (!this.isInitialized()) {
      return;
    }
    this.window.Intercom('showNewMessage', message);
  };
}

export const intercomService = new IntercomService(window);
