/**
 * Labstep
 */

import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import EntityUserActionManageList from 'labstep-web/components/EntityUser/Action/Manage/List';
import { ICONS } from 'labstep-web/constants/icons';
import {
  getEditable,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import {
  EntityUser
} from 'labstep-web/models/entity-user.model';
import React from 'react';

/**
 * Column definition for Collaborators
 * @returns Column definition
 */
const colDefEntityUser = {
  colId: 'entity_users',
  headerName: 'Collaborators',
  headerComponentParams: {
    icon: ICONS.user.secondary,
  },
  cellRenderer: (params) => {
    if (!params.data) {
      return '';
    }
    return <EntityUserActionManageList entity={params.data} />;
  },
  cellEditor: (params) => {
    const { data } = params;
    return (
      <EntityUserActionManage
        entity={data}
        viewComponent={null}
        open
        onClose={(): void => stopEditingAndFocus(params)}
      />
    );
  },
  cellEditorPopup: true,
  editable: getEditable(),
  cellStyle: { display: 'flex', alignItems: 'center' },
  valueGetterExport: (params): string =>
    params.node?.data && params.node?.data?.entity_users_preview
      ? params.node?.data?.entity_users_preview
          .map(
            (entityUser: EntityUser) => entityUser.user?.name || '',
          )
          .join(', ')
      : '',
};

export default colDefEntityUser;
