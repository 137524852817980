/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { objectOrFunction } from 'labstep-web/services/react.service';
import colDefEntityLink from '../entityLink';
import { colDefNameType } from './types';

export const COLUMN_WIDTH_NAME = 340;

export const colDefName: colDefNameType = (entityName, options?) => ({
  ...colDefEntityLink(options),
  colId: 'name',
  headerComponentParams: (params) => ({
    ...objectOrFunction(
      colDefEntityLink(options).headerComponentParams,
      params,
    ),
    icon: ICONS[entityName].primary,
    sort: 'name',
  }),
  headerName: getHumanReadableEntityName(entityName, false, true),
  pinned: 'left',
  width: COLUMN_WIDTH_NAME,
  lockPosition: true,
  valueGetter: (params) =>
    params.data?.displayName || 'Untitled Resource',
});

export default colDefName;
