/**
 * Labstep
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import FilterAvailableResourceItemCount from 'labstep-web/components/Filter/AvailableResourceItemCount';
import { ICONS } from 'labstep-web/constants/icons';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';

/**
 * Column definition for available items
 */
const colDefAvailableItems: ColDef<Resource> = {
  colId: 'available_items',
  headerName: 'Available Items',
  headerComponentParams: {
    icon: ICONS.resource.info.available,
    filter: (props) => (
      <FilterAvailableResourceItemCount {...props} />
    ),
  },
  cellRenderer: (params) => {
    if (!params.data) {
      return '';
    }
    return (
      <EntityLink
        entity={params.data}
        children={
          <span>{params.data.available_resource_item_count}</span>
        }
      />
    );
  },
  editable: false,
  valueGetter: (params) => params.data.available_resource_item_count,
  valueGetterExport: (params) =>
    params.node?.data?.available_resource_item_count
      ? String(params.node?.data?.available_resource_item_count)
      : '0',
};

export default colDefAvailableItems;
