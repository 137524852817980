/**
 * Labstep
 */

import React from 'react';
import Message from 'labstep-web/core/Message';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { ModalContentProps } from './types';
import { getPrice, getIntervalAdverb } from './utils';

export const ModalContent: React.FC<ModalContentProps> = ({
  organizationPlan,
  totalUsers,
}) => {
  const seatPrice = getPrice(organizationPlan, 1);
  const currentPrice = getPrice(
    organizationPlan,
    organizationPlan.quantity || 0,
  );
  const newPrice = getPrice(organizationPlan, totalUsers);

  return (
    <div>
      <p>
        Adding these users will require more seats than you currently
        pay for, which will amend your plan:
      </p>
      <div className={styles.plan}>
        <div className={styles.priceContainer}>
          <div className={styles.info}>Current</div>
          <div className={styles.price}>
            <b>{organizationPlan.quantity}</b> Seats at{' '}
            <b>{seatPrice}</b>
            /user
          </div>
          <div className={styles.price}>
            {`${getIntervalAdverb(
              organizationPlan.interval,
            )} payment `}
            <b>{currentPrice}</b>
          </div>
        </div>
        <Icon name="arrow right" />
        <div className={styles.priceContainer}>
          <div className={styles.info}>Amended</div>
          <div className={styles.price}>
            <b>{totalUsers}</b> Seats at <b>{seatPrice}</b>/user
          </div>
          <div className={styles.price}>
            {`${getIntervalAdverb(
              organizationPlan.interval,
            )} payment `}
            <b>{newPrice}</b>
          </div>
        </div>
      </div>
      <Message warning className={styles.message}>
        Please note that you will also be charged the amended price
        for the rest of the current billing period.
      </Message>
      <p>Are you sure you wish to continue?</p>
    </div>
  );
};
