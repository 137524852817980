/**
 * Labstep
 */

import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  getEntityDefault,
} from 'labstep-web/core/DataGrid/utils';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { colDefAmountType } from './types';
import { getValueSetter } from './utils';
/**
 * Column definition for Protocol Value amount
 * @param options Options
 * @returns Column definition
 */
const colDefAmount: colDefAmountType = (options = {}) => {
  const { getNestedEntity, columnProps, updateUnit } = options;
  const editable = getEditable(getNestedEntity, () => [
    'amount',
    'unit',
  ]);
  const getEntity = getNestedEntity || getEntityDefault;
  const cellRenderer: ColDef<
    ProtocolValue | ResourceItem
  >['cellRenderer'] = (
    params,
  ): string | React.ReactElement | null => {
    const fallback = (
      <DataGridPlaceholder
        params={params}
        editable={editable}
        placeholder="No amount"
      />
    );
    const entity = getEntity(params);
    return entity instanceof ProtocolValue ||
      entity instanceof ResourceItem
      ? entity.amountUnitTextNoUnitOnly || fallback
      : fallback;
  };

  return {
    cellRenderer,
    editable,
    valueGetter: (params): string | boolean | null => {
      const entity = getEntity(params);
      // we must return false instead of null
      // otherwise the grid thinks the value has not updated
      // when the entity is set and its value is null
      return entity ? entity.amountUnitTextNoUnitOnly : false;
    },
    valueGetterExport: (params) =>
      params.node?.data?.amountUnitTextNoUnitOnly || '',
    valueSetter: getValueSetter(getEntity, updateUnit),
    ...columnProps,
  };
};

export default colDefAmount;
