/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { IProtocolValueDeleteActionProps } from './types';

export const ProtocolValueDeleteAction: React.FC<
  IProtocolValueDeleteActionProps
> = ({ protocolValue, ...rest }) => (
  <EntityActionDelete
    dataCy="components/ProtocolValue/Action/Delete"
    {...rest}
    entity={protocolValue}
  />
);

export default ProtocolValueDeleteAction;
