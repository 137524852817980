/**
 * Labstep
 *
 * @module screens/ExperimentWorkflow/Show/Center
 * @desc ExperimentWorkflow Show Center
 */

import ExperimentWorkflowEntityStateIndicator from 'labstep-web/components/EntityStateWorkflow/StatusIndicator';
import EntityUserCard from 'labstep-web/components/EntityUser/Card';
import ExperimentFormShowEditStateContainer from 'labstep-web/components/Experiment/Form/ShowEdit/State/Container';
import ExperimentWorkflowActionMenu from 'labstep-web/components/ExperimentWorkflow/Action/Menu';
import { Action } from 'labstep-web/components/ExperimentWorkflow/Action/Menu/types';
import ExperimentWorkflowActionUpdateStatus from 'labstep-web/components/ExperimentWorkflow/Action/UpdateStatus';
import ExperimentWorkflowShowEditName from 'labstep-web/components/ExperimentWorkflow/Form/ShowEdit/Name';
import ExperimentWorkflowInfoDateRange from 'labstep-web/components/ExperimentWorkflow/Info/DateRange';
import ExperimentWorkflowInfoLockedBy from 'labstep-web/components/ExperimentWorkflow/Info/LockedBy';
import FolderManager from 'labstep-web/components/Folder/Manager';
import SignatureSignAndRejectButtons from 'labstep-web/components/Signature/SignAndRejectButtons';
import TagManager from 'labstep-web/components/Tag/Manager';
import InformationCard from 'labstep-web/core/Card/Information';
import Flex from 'labstep-web/core/Flex';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import React from 'react';
import { ICenterProps } from './types';

const Center: React.FC<ICenterProps> = ({ experimentWorkflow }) => {
  return (
    <InformationCard
      state={
        !experimentWorkflow.is_template &&
        experimentWorkflow.entity_state_workflow && (
          <>
            <ExperimentWorkflowEntityStateIndicator
              experimentWorkflow={experimentWorkflow}
            />
          </>
        )
      }
      additionalActions={
        <>
          <EntityUserCard entity={experimentWorkflow} />
          <FolderManager parentEntity={experimentWorkflow} />
          <TagManager parentEntity={experimentWorkflow} />
        </>
      }
      actionMenu={
        <ExperimentWorkflowActionMenu
          experimentWorkflow={experimentWorkflow}
          actions={[
            Action.add_to_folder,
            Action.tag,
            Action.duplicate,
            Action.print,
            Action.share,
            Action.delete,
            Action.transfer_ownership,
            Action.lock,
            Action.export,
            Action.assign,
          ]}
        />
      }
      primaryInfo={
        <ExperimentWorkflowShowEditName
          experimentWorkflow={experimentWorkflow}
        />
      }
      secondaryInfo={
        !experimentWorkflow.is_template && (
          <Flex wrap style={{ alignItems: 'baseline' }}>
            <ExperimentWorkflowActionUpdateStatus
              experimentWorkflow={experimentWorkflow}
            />
            <ExperimentWorkflowInfoDateRange
              experimentWorkflow={experimentWorkflow}
            />
          </Flex>
        )
      }
      bottom={
        <>
          <ExperimentWorkflowInfoLockedBy
            experimentWorkflow={experimentWorkflow}
          />

          <SignatureSignAndRejectButtons
            experimentWorkflow={experimentWorkflow}
          />
          <ReadOnMountHOC
            type="entity"
            entityName={experimentWorkflow.root_experiment.entityName}
            id={experimentWorkflow.root_experiment.id}
            loading={{ loader: 'placeholder' }}
            children={({ entity: experiment }) => (
              <ExperimentFormShowEditStateContainer
                experiment={experiment}
                experimentWorkflow={experimentWorkflow}
              />
            )}
          />
        </>
      }
    />
  );
};

export default Center;
