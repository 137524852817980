/**
 * Labstep
 *
 * @desc Selectors for Entity/Can/hooks
 */

import {
  Action,
  ICanCreateProps,
  ICanProps,
  PermissionEntity,
} from 'labstep-web/components/Entity/Can/types';
import { TOP_LEVEL_PERMISSION_ENTITIES_BY_ENTITY_NAME } from 'labstep-web/constants/permissions';
import { Group } from 'labstep-web/models/group.model';
import { AuthorizationService } from 'labstep-web/services/authorization.service';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import {
  selectIsLocked,
  selectPermissionEntity,
} from 'labstep-web/state/new/permission';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import { createSelector } from 'reselect';

export const hasAccessEntity = (
  permissionEntity: PermissionEntity | null,
  isLocked: boolean,
  mappedAction: Action,
  field?: string | string[],
) => {
  if (!permissionEntity) {
    return false;
  }

  const permissionEntityEntityName = permissionEntity.is_template
    ? `${permissionEntity.entityName}_template`
    : permissionEntity.entityName;

  if (
    isLocked &&
    !AuthorizationService.isActionAllowedForLockedEntity(
      permissionEntityEntityName,
      permissionEntity,
      mappedAction,
      field,
    )
  ) {
    return false;
  }

  try {
    if ('allowed_actions' in permissionEntity) {
      return AuthorizationService.hasAccess(
        permissionEntityEntityName,
        permissionEntity,
        mappedAction,
        field,
      );
    }
    return false;
  } catch (e) {
    bugsnagService.notify(e, {
      permissionEntity,
      mappedAction,
      isLocked,
    });
    return false;
  }
};

/**
 * @description function to make selector for getHasAccess
 */
export const makeSelectHasAccess = () =>
  createSelector(
    selectPermissionEntity,
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
      action: Action,
    ) => action,
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
    ) => {
      let append = '';
      if (!Array.isArray(id)) {
        try {
          const entity = state.entities[entityName].byId[id];
          if (entity.is_template && entityName !== 'protocol_timer') {
            append = '_template';
          }
        } catch (e) {
          bugsnagService.notify(e, {
            entityName,
            id,
          });
        }
      }

      // TODO: Test
      // This is so that resource_item_template is not editable
      // if resource_template edit are not allowed but resource_item edits are allowed
      return `${entityName}${append}`;
    },
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
    ): boolean => {
      return selectIsLocked(state, entityName, id);
    },
    (
      state: any,
      entityName: ICanProps['entityName'],
      id: ICanProps['id'] | ICanProps['id'][],
      action: Action,
      field?: string | string[],
    ) => field,
    (
      permissionEntity: PermissionEntity | PermissionEntity[] | null,
      action: Action,
      entityName: string,
      isLocked: boolean,
      field?: string | string[],
    ) => {
      let mappedAction = action;
      if (
        !TOP_LEVEL_PERMISSION_ENTITIES_BY_ENTITY_NAME.includes(
          entityName,
        )
      ) {
        mappedAction = `${entityName}:${action}` as Action;
      }

      if (Array.isArray(permissionEntity)) {
        if (permissionEntity.length === 0) {
          return false;
        }

        return permissionEntity.some((entity) =>
          hasAccessEntity(entity, isLocked, mappedAction, field),
        );
      }

      return hasAccessEntity(
        permissionEntity,
        isLocked,
        mappedAction,
        field,
      );
    },
  );

export const makeSelectHasAccessGroup = () =>
  createSelector<
    readonly ((
      state: LabstepReduxState,
      action: string,
      groupId?: Group['id'],
    ) => any)[],
    boolean
  >(
    (state: any, action: string) => action,
    (state: any, action: string, groupId?: Group['id']) => {
      if (!groupId) {
        return selectEntity(state, 'group', state.activeGroupId);
      }
      return selectEntity(state, 'group', groupId);
    },
    (action: string, group: Group) => {
      return AuthorizationService.hasGroupAccess(action, group);
    },
  );

export const useHasAccessCreate = (
  permissionEntity: PermissionEntity | null,
  entityName: string,
  createAction: Action,
  isLocked?: boolean,
) => {
  if (!permissionEntity) {
    return false;
  }

  if (
    isLocked &&
    !AuthorizationService.isActionAllowedForLockedEntity(
      entityName,
      permissionEntity,
      createAction,
    )
  ) {
    return false;
  }

  return AuthorizationService.hasAccess(
    permissionEntity.entityName,
    permissionEntity,
    createAction,
  );
};

export const makeSelectHasAccessCreate = () =>
  createSelector<
    readonly ((
      state: LabstepReduxState,
      entityName: string,
      parentName: ICanCreateProps['parentName'],
      parentId: ICanCreateProps['parentId'],
      isLocked?: boolean,
    ) => any)[],
    boolean
  >(
    (
      state: any,
      entityName: string,
      parentName?: ICanCreateProps['parentName'],
      parentId?: ICanCreateProps['parentId'],
    ) => {
      if (!parentName || !parentId) {
        return selectPermissionEntity(
          state,
          'group',
          state.activeGroupId,
        );
      }
      return selectPermissionEntity(state, parentName, parentId);
    },
    (state: any, entityName: string) => entityName,
    (
      state: any,
      entityName: string,
      parentName?: ICanCreateProps['parentName'],
      parentId?: ICanCreateProps['parentId'],
    ) => {
      if (!parentName) {
        if (!state.activeGroupId) {
          return false;
        }
        return selectIsLocked(state, 'group', state.activeGroupId);
      }

      return selectIsLocked(state, parentName, parentId);
    },
    (
      permissionEntity: PermissionEntity | PermissionEntity[] | null,
      entityName: string,
      isLocked?: boolean,
    ) => {
      const createAction = `${entityName}:create` as Action;

      if (Array.isArray(permissionEntity)) {
        if (permissionEntity.length === 0) {
          return false;
        }

        return permissionEntity.some((entity) =>
          useHasAccessCreate(
            entity,
            entityName,
            createAction,
            isLocked,
          ),
        );
      }

      return useHasAccessCreate(
        permissionEntity,
        entityName,
        createAction,
        isLocked,
      );
    },
  );
