/**
 * Labstep
 *
 * @module components/Table/Form/Edit
 * @desc Edit form for protocol table
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import TableEditContainer from 'labstep-web/containers/Table/Edit';
import Loadable from 'labstep-web/core/Loadable';
import React from 'react';
import styles from './styles.module.scss';
import {
  ITableFormEditContainerProps,
  ITableFormEditProps,
} from './types';

export const Spreadsheet = React.lazy(
  () => import('labstep-web/core/SpreadJS/spreadsheet'),
);

export const TableFormEditComponent: React.FC<
  ITableFormEditProps
> = ({
  table,
  readOnly = false,
  allowLocking,
  debouncingStatus,
  onChange,
  onError,
  onStatusChange,
}) => {
  const canEdit = useHasAccess(
    table.entityName,
    table.idAttr,
    Action.edit,
    ['data'],
  );

  React.useEffect(() => {
    if (onStatusChange) {
      onStatusChange(debouncingStatus);
    }
  }, [debouncingStatus.isFetching]);

  return (
    <div className={styles.container}>
      <Loadable>
        <Spreadsheet
          data-cy={`components/Table/Form/Edit:${
            readOnly || !canEdit === true ? 'readOnly' : 'editable'
          }`}
          data={table.data}
          readOnly={readOnly || !canEdit}
          onChange={onChange}
          onError={onError}
          allowLocking={allowLocking}
        />
      </Loadable>
    </div>
  );
};

export const TableFormEditContainer: React.FC<
  ITableFormEditContainerProps
> = ({ table, options, ...rest }) => (
  <TableEditContainer table={table} options={options}>
    {(containerProps) => (
      <TableFormEditComponent {...containerProps} {...rest} />
    )}
  </TableEditContainer>
);

export default TableFormEditContainer;
