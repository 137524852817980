/**
 * Labstep
 *
 * @module components/Table/Card
 * @desc Card component for Table
 */

import { ICONS } from 'labstep-web/constants/icons';
import AutoSaveSpinner from 'labstep-web/core/AutoSave/Spinner';
import CollapsibleCard from 'labstep-web/core/Collapsible/Card';
import Icon from 'labstep-web/core/Icon';
import Loadable from 'labstep-web/core/Loadable';
import React from 'react';
import { ITableCardProps } from './types';

export const SpreadsheetInline = React.lazy(
  () => import('labstep-web/core/SpreadJS/spreadsheet-inline'),
);

export const TableCardItem: React.FC<ITableCardProps> = ({
  name,
  debouncingStatus,
  toggleModal,
  actions,
  data,
  readOnly,
  onChange,
  hideIcon,
  emptyState,
  allowLocking,
  ...rest
}) => (
  <CollapsibleCard.Item
    icon={hideIcon ? undefined : 'table'}
    name={name}
    actions={({ expanded }) => (
      <>
        {expanded && <AutoSaveSpinner status={debouncingStatus} />}{' '}
        {toggleModal && (
          <Icon
            data-cy="components/Table/Card:preview"
            name={ICONS.file.actions.preview}
            color="blue"
            disabled={debouncingStatus?.isFetching}
            onClick={toggleModal}
          />
        )}
        {actions}
      </>
    )}
    content={
      emptyState && !data ? (
        emptyState
      ) : (
        <Loadable>
          <SpreadsheetInline
            data={data}
            readOnly={readOnly}
            onChange={onChange}
            allowLocking={allowLocking}
            {...rest}
          />
        </Loadable>
      )
    }
  />
);

export const TableCard: React.FC<ITableCardProps> = (props) => (
  <div
    onDoubleClick={(e) => {
      e.stopPropagation();
    }}
  >
    <CollapsibleCard>
      <TableCardItem {...props} />
    </CollapsibleCard>
  </div>
);

export default TableCard;
