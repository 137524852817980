/**
 * Labstep
 *
 * @module components/Experiment/Form/ShowEdit/StartedAt
 * @desc Showing experiment.started_at + allow for update
 */

import FormShowEditDate from 'labstep-web/core/Form/ShowEdit/Date';
import React from 'react';
import { IExperimentFormShowEditStartedAtProps } from './types';

const ExperimentFormShowEditStartedAt: React.FC<
  IExperimentFormShowEditStartedAtProps
> = ({ experiment }) => {
  return (
    <FormShowEditDate
      dataCy="components/Experiment/Form/ShowEdit/StartedAt"
      entity={experiment}
      field="started_at"
      label="start"
      dateTimePickerOptions={{ maxDate: experiment.ended_at }}
    />
  );
};

export default ExperimentFormShowEditStartedAt;
