/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/ResourceItem/Input
 * @desc ProtocolValue Info
 */

import FormShowEditSearchSelectModal from 'labstep-web/core/Form/ShowEdit/SearchSelect/Modal';
import React from 'react';
import { IProtocolValueShowEditResourceItemInputProps } from './types';
import { getProps } from './utils';

export const ProtocolValueShowEditResourceItemInput: React.FC<
  IProtocolValueShowEditResourceItemInputProps
> = ({ protocolValue, children, type = 'button' }) => (
  <FormShowEditSearchSelectModal
    dataCy="components/ProtocolValue/Form/ShowEdit/ResourceItem/Input"
    {...getProps(protocolValue, children, type)}
  />
);

export default ProtocolValueShowEditResourceItemInput;
