/**
 * Labstep
 */

interface IPermissionLabels {
  [key: string]: string;
}

export const PERMISSION_LABELS: IPermissionLabels = {
  create: 'Can Create',
  edit: 'Can Edit',
  delete: 'Can Delete',
  restore: 'Can Restore',
  lock: 'Can Lock',
  unlock: 'Can Unlock',
  assign: 'Can Assign',
  share: 'Can Share',
  comment: 'Can Comment',
  sign: 'Can Sign',
  create_items: 'Create Items',
  book: 'Can Book',
  send_data: 'Can Send Data',
  folder: 'Add to /Remove from Collection',
  tag: 'Add/Remove Tag',
  jupyter: 'Create/Edit Jupyter Notebook',
};

export interface IPermissionRoleSettingsPostParameters {
  [key: string]: {
    [key: string]: {
      entity_name: string;
      action: string;
    };
  };
}

export const PermissionRoleSettingsPostParameters: IPermissionRoleSettingsPostParameters =
  {
    experiment_workflow: {
      create: {
        entity_name: 'group',
        action: 'experiment_workflow:create',
      },
      edit: {
        entity_name: 'experiment_workflow',
        action: 'edit',
      },
      assign: {
        entity_name: 'experiment_workflow',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'experiment_workflow',
        action: 'delete',
      },
      share: {
        entity_name: 'experiment_workflow',
        action: 'permission:*',
      },
      lock: {
        entity_name: 'experiment_workflow',
        action: 'lock',
      },
      unlock: {
        entity_name: 'experiment_workflow',
        action: 'unlock',
      },
      comment: {
        entity_name: 'experiment_workflow',
        action: 'comment:*',
      },
      sign: {
        entity_name: 'experiment_workflow',
        action: 'signature:*',
      },
      folder: {
        entity_name: 'experiment_workflow',
        action: 'folder:add_remove',
      },
      tag: {
        entity_name: 'experiment_workflow',
        action: 'tag:add_remove',
      },
    },
    experiment_workflow_template: {
      create: {
        entity_name: 'group',
        action: 'experiment_workflow_template:create',
      },
      edit: {
        entity_name: 'experiment_workflow_template',
        action: 'edit',
      },
      assign: {
        entity_name: 'experiment_workflow_template',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'experiment_workflow_template',
        action: 'delete',
      },
      share: {
        entity_name: 'experiment_workflow_template',
        action: 'permission:*',
      },
      lock: {
        entity_name: 'experiment_workflow_template',
        action: 'lock',
      },
      unlock: {
        entity_name: 'experiment_workflow_template',
        action: 'unlock',
      },
      comment: {
        entity_name: 'experiment_workflow_template',
        action: 'comment:*',
      },
    },
    protocol_collection: {
      create: {
        entity_name: 'group',
        action: 'protocol_collection:create',
      },
      edit: {
        entity_name: 'protocol_collection',
        action: 'edit',
      },
      assign: {
        entity_name: 'protocol_collection',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'protocol_collection',
        action: 'delete',
      },
      share: {
        entity_name: 'protocol_collection',
        action: 'permission:*',
      },
      comment: {
        entity_name: 'protocol_collection',
        action: 'comment:*',
      },
      folder: {
        entity_name: 'protocol_collection',
        action: 'folder:add_remove',
      },
      tag: {
        entity_name: 'protocol_collection',
        action: 'tag:add_remove',
      },
    },
    resource_template: {
      create: {
        entity_name: 'group',
        action: 'resource_template:create',
      },
      edit: {
        entity_name: 'resource_template',
        action: 'edit',
      },
      delete: {
        entity_name: 'resource_template',
        action: 'delete',
      },
      comment: {
        entity_name: 'resource_template',
        action: 'comment:*',
      },
      share: {
        entity_name: 'resource_template',
        action: 'permission:*',
      },
    },
    resource: {
      create: {
        entity_name: 'group',
        action: 'resource:create',
      },
      edit: {
        entity_name: 'resource',
        action: 'edit',
      },
      assign: {
        entity_name: 'resource',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'resource',
        action: 'delete',
      },
      share: {
        entity_name: 'resource',
        action: 'permission:*',
      },
      comment: {
        entity_name: 'resource',
        action: 'comment:*',
      },
      tag: {
        entity_name: 'resource',
        action: 'tag:add_remove',
      },
      create_items: {
        entity_name: 'resource',
        action: 'resource_item:create',
      },
    },
    resource_location: {
      create: {
        entity_name: 'group',
        action: 'resource_location:create',
      },
      edit: {
        entity_name: 'resource_location',
        action: 'edit',
      },
      assign: {
        entity_name: 'resource_location',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'resource_location',
        action: 'delete',
      },
      comment: {
        entity_name: 'resource_location',
        action: 'comment:*',
      },
    },
    order_request: {
      create: {
        entity_name: 'group',
        action: 'order_request:create',
      },
      edit: {
        entity_name: 'order_request',
        action: 'edit',
      },
      assign: {
        entity_name: 'order_request',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'order_request',
        action: 'delete',
      },
      share: {
        entity_name: 'order_request',
        action: 'permission:*',
      },
      comment: {
        entity_name: 'order_request',
        action: 'comment:*',
      },
      tag: {
        entity_name: 'order_request',
        action: 'tag:add_remove',
      },
    },
    purchase_order: {
      create: {
        entity_name: 'group',
        action: 'purchase_order:create',
      },
      edit: {
        entity_name: 'purchase_order',
        action: 'edit',
      },
      assign: {
        entity_name: 'purchase_order',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'purchase_order',
        action: 'delete',
      },
      comment: {
        entity_name: 'purchase_order',
        action: 'comment:*',
      },
      tag: {
        entity_name: 'purchase_order',
        action: 'tag:add_remove',
      },
    },
    device_template: {
      create: {
        entity_name: 'group',
        action: 'device_template:create',
      },
      edit: {
        entity_name: 'device_template',
        action: 'edit',
      },
      delete: {
        entity_name: 'device_template',
        action: 'delete',
      },
      comment: {
        entity_name: 'device_template',
        action: 'comment:*',
      },
      share: {
        entity_name: 'device_template',
        action: 'permission:*',
      },
    },
    device: {
      create: {
        entity_name: 'group',
        action: 'device:create',
      },
      edit: {
        entity_name: 'device',
        action: 'edit',
      },
      assign: {
        entity_name: 'device',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'device',
        action: 'delete',
      },
      share: {
        entity_name: 'device',
        action: 'permission:*',
      },
      comment: {
        entity_name: 'device',
        action: 'comment:*',
      },
      send_data: {
        entity_name: 'device',
        action: 'device_data:*',
      },
      book: {
        entity_name: 'device',
        action: 'device_booking:*',
      },
      tag: {
        entity_name: 'device',
        action: 'tag:add_remove',
      },
    },
    tag: {
      create: {
        entity_name: 'group',
        action: 'tag:create',
      },
      edit: {
        entity_name: 'tag',
        action: 'edit',
      },
      delete: {
        entity_name: 'tag',
        action: 'delete',
      },
    },
    folder: {
      create: {
        entity_name: 'group',
        action: 'folder:create',
      },
      edit: {
        entity_name: 'folder',
        action: 'edit',
      },
      delete: {
        entity_name: 'folder',
        action: 'delete',
      },
    },
    group: {
      comment: {
        entity_name: 'group',
        action: 'comment:*',
      },
      jupyter: {
        entity_name: 'group',
        action: 'jupyter_notebook:*',
      },
    },
    entity_state_workflow: {
      create: {
        entity_name: 'group',
        action: 'entity_state_workflow:create',
      },
      edit: {
        entity_name: 'entity_state_workflow',
        action: 'edit',
      },
      assign: {
        entity_name: 'entity_state_workflow',
        action: 'entity_user:*',
      },
      delete: {
        entity_name: 'entity_state_workflow',
        action: 'delete',
      },
      share: {
        entity_name: 'entity_state_workflow',
        action: 'permission:*',
      },
    },
  };

export interface IPermissionRoleSections {
  [key: string]: {
    [key: string]: string[];
  };
}

export const PermissionRoleSections: IPermissionRoleSections = {
  experiment_workflow: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
    lock: ['all', 'assigned', 'none'],
    unlock: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    sign: ['all', 'assigned', 'none'],
    folder: ['all', 'assigned', 'none'],
    tag: ['all', 'assigned', 'none'],
  },
  experiment_workflow_template: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
    lock: ['all', 'assigned', 'none'],
    unlock: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
  },
  protocol_collection: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    folder: ['all', 'assigned', 'none'],
    tag: ['all', 'assigned', 'none'],
  },
  resource_template: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
  },
  resource: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    tag: ['all', 'assigned', 'none'],
    create_items: ['all', 'assigned', 'none'],
  },
  resource_location: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
  },
  order_request: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    tag: ['all', 'assigned', 'none'],
  },
  purchase_order: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    tag: ['all', 'assigned', 'none'],
  },
  device_template: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
  },
  device: {
    create: ['all', 'none'],
    edit: ['all', 'assigned', 'none'],
    assign: ['all', 'assigned', 'none'],
    delete: ['all', 'assigned', 'none'],
    share: ['all', 'assigned', 'none'],
    comment: ['all', 'assigned', 'none'],
    send_data: ['all', 'assigned', 'none'],
    book: ['all', 'assigned', 'none'],
    tag: ['all', 'assigned', 'none'],
  },
  tag: {
    create: ['all', 'none'],
    edit: ['all', 'none'],
    delete: ['all', 'none'],
  },
  folder: {
    create: ['all', 'none'],
    edit: ['all', 'none'],
    delete: ['all', 'none'],
  },
  group: {
    comment: ['all', 'none'],
    jupyter: ['all', 'none'],
  },
  entity_state_workflow: {
    create: ['all', 'none'],
    edit: ['all', 'none'],
    assign: ['all', 'none'],
    delete: ['all', 'none'],
    share: ['all', 'none'],
  },
};

type DefaultPermissionRoleSettingType = 'none' | 'all';

interface DefaultPermissionRoleSettings {
  experiment_workflow: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
    lock: DefaultPermissionRoleSettingType;
    unlock: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    sign: DefaultPermissionRoleSettingType;
    folder: DefaultPermissionRoleSettingType;
    tag: DefaultPermissionRoleSettingType;
  };
  experiment_workflow_template: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
    lock: DefaultPermissionRoleSettingType;
    unlock: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
  };
  protocol_collection: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    folder: DefaultPermissionRoleSettingType;
    tag: DefaultPermissionRoleSettingType;
  };
  resource_template: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
  };
  resource: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    tag: DefaultPermissionRoleSettingType;
    create_items: DefaultPermissionRoleSettingType;
  };
  resource_location: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
  };
  order_request: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    tag: DefaultPermissionRoleSettingType;
  };
  purchase_order: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    tag: DefaultPermissionRoleSettingType;
  };
  device_template: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
  };
  device: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
    comment: DefaultPermissionRoleSettingType;
    send_data: DefaultPermissionRoleSettingType;
    book: DefaultPermissionRoleSettingType;
    tag: DefaultPermissionRoleSettingType;
  };
  tag: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
  };
  folder: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
  };
  group: {
    comment: DefaultPermissionRoleSettingType;
    jupyter: DefaultPermissionRoleSettingType;
  };
  entity_state_workflow: {
    create: DefaultPermissionRoleSettingType;
    edit: DefaultPermissionRoleSettingType;
    assign: DefaultPermissionRoleSettingType;
    delete: DefaultPermissionRoleSettingType;
    share: DefaultPermissionRoleSettingType;
  };
  [key: string]: {
    [key: string]: DefaultPermissionRoleSettingType;
  };
}

export const PERMISSION_ROLE_OWNER = {
  guid: 'owner',
  name: 'Owner',
};

export const PERMISSION_ROLE_OWNER_SETTINGS: DefaultPermissionRoleSettings =
  {
    experiment_workflow: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
      lock: 'all',
      unlock: 'all',
      comment: 'all',
      sign: 'all',
      folder: 'all',
      tag: 'all',
    },
    experiment_workflow_template: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
      lock: 'all',
      unlock: 'all',
      comment: 'all',
    },
    protocol_collection: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
      comment: 'all',
      folder: 'all',
      tag: 'all',
    },
    resource_template: {
      create: 'all',
      edit: 'all',
      delete: 'all',
      comment: 'all',
      share: 'all',
    },
    resource: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
      comment: 'all',
      tag: 'all',
      create_items: 'all',
    },
    resource_location: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      comment: 'all',
    },
    order_request: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
      comment: 'all',
      tag: 'all',
    },
    purchase_order: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      comment: 'all',
      tag: 'all',
    },
    device_template: {
      create: 'all',
      edit: 'all',
      delete: 'all',
      comment: 'all',
      share: 'all',
    },
    device: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
      comment: 'all',
      send_data: 'all',
      book: 'all',
      tag: 'all',
    },
    tag: {
      create: 'all',
      edit: 'all',
      delete: 'all',
    },
    folder: {
      create: 'all',
      edit: 'all',
      delete: 'all',
    },
    group: {
      comment: 'all',
      jupyter: 'all',
    },
    entity_state_workflow: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'all',
    },
  };

export const PERMISSION_ROLE_EDITOR = {
  guid: 'editor',
  name: 'Editor',
};

export const PERMISSION_ROLE_EDITOR_SETTINGS: DefaultPermissionRoleSettings =
  {
    experiment_workflow: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'none',
      lock: 'all',
      unlock: 'none',
      comment: 'all',
      sign: 'all',
      folder: 'all',
      tag: 'all',
    },
    experiment_workflow_template: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'none',
      lock: 'all',
      unlock: 'none',
      comment: 'all',
    },
    protocol_collection: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'none',
      comment: 'all',
      folder: 'all',
      tag: 'all',
    },
    resource_template: {
      create: 'all',
      edit: 'all',
      delete: 'all',
      comment: 'all',
      share: 'none',
    },
    resource: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'none',
      comment: 'all',
      tag: 'all',
      create_items: 'all',
    },
    resource_location: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      comment: 'all',
    },
    order_request: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'none',
      comment: 'all',
      tag: 'all',
    },
    purchase_order: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      comment: 'all',
      tag: 'all',
    },
    device_template: {
      create: 'all',
      edit: 'all',
      delete: 'all',
      comment: 'all',
      share: 'none',
    },
    device: {
      create: 'all',
      edit: 'all',
      assign: 'all',
      delete: 'all',
      share: 'none',
      comment: 'all',
      send_data: 'all',
      book: 'all',
      tag: 'all',
    },
    tag: {
      create: 'all',
      edit: 'all',
      delete: 'all',
    },
    folder: {
      create: 'all',
      edit: 'all',
      delete: 'all',
    },
    group: {
      comment: 'all',
      jupyter: 'all',
    },
    entity_state_workflow: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
    },
  };

export const PERMISSION_ROLE_VIEWER = {
  guid: 'viewer',
  name: 'Viewer',
};

export const PERMISSION_ROLE_VIEWER_SETTINGS: DefaultPermissionRoleSettings =
  {
    experiment_workflow: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
      lock: 'none',
      unlock: 'none',
      comment: 'none',
      sign: 'none',
      folder: 'none',
      tag: 'none',
    },
    experiment_workflow_template: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
      lock: 'none',
      unlock: 'none',
      comment: 'none',
    },
    protocol_collection: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
      comment: 'none',
      folder: 'none',
      tag: 'none',
    },
    resource_template: {
      create: 'none',
      edit: 'none',
      delete: 'none',
      comment: 'none',
      share: 'none',
    },
    resource: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
      comment: 'none',
      tag: 'none',
      create_items: 'none',
    },
    resource_location: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      comment: 'none',
    },
    order_request: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
      comment: 'none',
      tag: 'none',
    },
    purchase_order: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      comment: 'none',
      tag: 'none',
    },
    device_template: {
      create: 'none',
      edit: 'none',
      delete: 'none',
      comment: 'none',
      share: 'none',
    },
    device: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
      comment: 'none',
      send_data: 'none',
      book: 'none',
      tag: 'none',
    },
    tag: {
      create: 'none',
      edit: 'none',
      delete: 'none',
    },
    folder: {
      create: 'none',
      edit: 'none',
      delete: 'none',
    },
    group: {
      comment: 'none',
      jupyter: 'none',
    },
    entity_state_workflow: {
      create: 'none',
      edit: 'none',
      assign: 'none',
      delete: 'none',
      share: 'none',
    },
  };

interface PermissionRoleSettings {
  name: string;
  settings: DefaultPermissionRoleSettings;
}

export const DEFAULT_PERMISSION_ROLE_SETTINGS: PermissionRoleSettings[] =
  [
    {
      name: 'Owner',
      settings: PERMISSION_ROLE_OWNER_SETTINGS,
    },
    {
      name: 'Editor',
      settings: PERMISSION_ROLE_EDITOR_SETTINGS,
    },
    {
      name: 'Viewer',
      settings: PERMISSION_ROLE_VIEWER_SETTINGS,
    },
  ];

export const DEFAULT_PERMISSION_ROLES = [
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_VIEWER,
];
